<template>
  <div class="">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
      
      <div class="col-auto">
        <button @click="printAll()" class="btn btn-secondary">
          <i class="bi bi-printer"></i>
          Imprimer
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Catégorie</th>
          <th scope="col">Unité</th>
          <th scope="col">Prix TTC</th>
          <th scope="col">TVA</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(invoice, index) in invoices" :key="index++">
          <td>
            {{ index }}
          </td>
          <td class="p-0 m-0">
            {{ invoice.reference }}
          </td>
          <td>
            {{ invoice.fullName }}
          </td>

          <td>
            <span v-if="invoice.section">
              {{ invoice.section.fullName }}
            </span>
          </td>

          <td>
            {{ invoice.measuring }}
          </td>

          <td>
            {{ parseFloat(invoice.priceTTC).toFixed(2) }}
          </td>

          <td>{{ invoice.tva }} %</td>

          <td class="d-flex">
            <button
              @click="
                $router.push({
                  name: 'invoices-edit',
                  params: {
                    reference: invoice.reference,
                  },
                })
              "
              class="btn"
            >
              <i class="bi bi-pencil-square"></i>
            </button>
            <button @click="remove(invoice)" class="btn">
              <i class="bi bi-trash text-danger"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("invoice", {
      invoices: "getInvoices",
    }),
    // imageUrl() {
    //   return (invoice) => `${invoice.image}`
    // },
  },
  mounted() {
    this.$store.dispatch("invoice/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("invoice/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("invoice/getAll");
    },

    async sort(value) {
      return await this.$store.commit("invoice/sort", value);
    },

    async remove(data) {
      let invoice = data;
      await this.$confirm({
        message: "Vous voulez supprimer l'article  " + invoice.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("invoice/destroy", invoice.reference);
          }
        },
      });
    },

    async printAll() {
      return await this.$store.dispatch("invoice/printAll");
    },
  },
};
</script>
